// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title__title---lhUL {
  color: var(--color-text);
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/title.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".title {\n  color: var(--color-text);\n  font-size: 20px;\n  line-height: 28px;\n  font-weight: 500;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `title__title---lhUL`
};
export default ___CSS_LOADER_EXPORT___;
