import React, { useContext } from 'react';
import styles from './icon-underlay.module.css';
import classnames from 'classnames';
import { AbstractStatusEnum } from '@models/abstract-status.enum';
import { Icon } from '@components/icon';
import { IconSourceUrl } from '@components/icon-source-url';
import { Nullable } from '@models/nullable.type';
import { capitalize } from '@utils/helpers';
import { ServiceContext } from '@services/service.provider';

type Props = {
  icon: string;
  status: AbstractStatusEnum;
  qaLocator?: string;
  isCompact?: boolean;
  url?: Nullable<string>;
};

export const IconUnderlay: React.FunctionComponent<Props> = ({
  icon,
  status,
  qaLocator,
  isCompact = false,
  url = null,
}) => {
  const { settingsService } = useContext(ServiceContext);

  return (
    <div className={classnames(styles.underlay, { [styles.compact]: isCompact }, styles[status])}>
      <IconSourceUrl
        url={url}
        className={styles.icon}
        qaLocator={qaLocator}
        fallbackIcon={<Icon name={icon} sizes={[72]} className={styles.icon} qaLocator={qaLocator} />}
        alt={capitalize(settingsService.settings.LiveChatTextResources.StatusIcon)}
      />
    </div>
  );
};
