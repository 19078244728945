// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.actions__actions--GsgXM {
  margin: calc(var(--button-spacing) * -1);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

* + .actions__actions--GsgXM {
  padding-top: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/chat/actions/actions.module.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,aAAa;EACb,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".actions {\n  margin: calc(var(--button-spacing) * -1);\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-end;\n}\n\n* + .actions {\n  padding-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `actions__actions--GsgXM`
};
export default ___CSS_LOADER_EXPORT___;
