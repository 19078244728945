// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.choose-phone__form--lmVFB {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 16px;
  margin-right: 11px;
  padding: 16px;
  background-color: var(--color-primary-contrast);
  border: 1px solid var(--color-primary);
  border-radius: var(--border-radius);
  box-shadow: var(--form-shadow);
}

[data-theme='dark'] .choose-phone__form--lmVFB {
  border-color: transparent;
}

.choose-phone__title--wQcfs {
  color: var(--color-text);
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 8px;
}

.choose-phone__description--MK5UN {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--color-text--lighten);
}

.choose-phone__fields--94q5j {
  margin-top: 8px;
}

.choose-phone__buttons--544br {
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.choose-phone__buttons--544br button {
  max-width: 148px;
}
`, "",{"version":3,"sources":["webpack://./src/components/choose-phone/choose-phone.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,+CAA+C;EAC/C,sCAAsC;EACtC,mCAAmC;EACnC,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".form {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  margin: 16px;\n  margin-right: 11px;\n  padding: 16px;\n  background-color: var(--color-primary-contrast);\n  border: 1px solid var(--color-primary);\n  border-radius: var(--border-radius);\n  box-shadow: var(--form-shadow);\n}\n\n[data-theme='dark'] .form {\n  border-color: transparent;\n}\n\n.title {\n  color: var(--color-text);\n  font-weight: 500;\n  font-size: 20px;\n  margin-bottom: 8px;\n}\n\n.description {\n  font-size: 16px;\n  line-height: 24px;\n  text-align: center;\n  color: var(--color-text--lighten);\n}\n\n.fields {\n  margin-top: 8px;\n}\n\n.buttons {\n  margin-top: 32px;\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n}\n\n.buttons button {\n  max-width: 148px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `choose-phone__form--lmVFB`,
	"title": `choose-phone__title--wQcfs`,
	"description": `choose-phone__description--MK5UN`,
	"fields": `choose-phone__fields--94q5j`,
	"buttons": `choose-phone__buttons--544br`
};
export default ___CSS_LOADER_EXPORT___;
