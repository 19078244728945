import { useBehaviorSubject } from '@hooks/use-behavior-subject';
import { ServiceContext } from '@services/service.provider';
import { throttle } from 'throttle-debounce';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useCallback, useContext, useEffect, useRef } from 'react';

interface ITranscriptProps {
  resetTranscript(): void;
  isListening: boolean;
  isSpeechRecognitionEnabled: boolean;
  transcript: string;
  startListening(): Promise<void>;
  stopListening(): void;
}

export const useFormMessageState = (): [string, (message: string) => void] => {
  const { liveChatService } = useContext(ServiceContext);
  const message = useBehaviorSubject(liveChatService.typedMessage$) || '';

  const setMessage = useCallback(
    (message: string): void => {
      liveChatService.setTypedMessageHandler(message);
    },
    [liveChatService],
  );

  return [message, setMessage];
};

export const useIsMessageMaxLength = (message: string): { maxMessageLength: number; isLengthExceeded: boolean } => {
  const { settingsService } = useContext(ServiceContext);
  const maxMessageLength = settingsService.settings.MaxMessageLength;
  const isLengthExceeded = maxMessageLength !== 0 && message.length > maxMessageLength;

  return {
    maxMessageLength,
    isLengthExceeded,
  };
};

export const useIsTyping = (message: string): void => {
  const { twilioConversationsService } = useContext(ServiceContext);
  const throttled = useRef(
    throttle(1000, (nextMessage: string) => {
      if (nextMessage.length) {
        twilioConversationsService.setTyping();
      }
    }),
  );

  useEffect(() => {
    throttled.current(message);
  }, [message]);
};

export const useSpeachRecognition = (): ITranscriptProps => {
  const { settingsService } = useContext(ServiceContext);
  const {
    transcript,
    browserSupportsSpeechRecognition: isSupprorted,
    resetTranscript,
    listening: isListening,
  } = useSpeechRecognition();
  const isSpeechRecognitionEnabled = settingsService.settings.IsSpeachRecognitionEnabled && isSupprorted;

  return {
    resetTranscript,
    isListening,
    isSpeechRecognitionEnabled,
    transcript,
    startListening: (): Promise<void> => SpeechRecognition.startListening(),
    stopListening: (): Promise<void> => SpeechRecognition.stopListening(),
  };
};
