// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-identification-form__form--dp7v- {
  display: flex;
  flex-direction: column;
  margin: 16px;
  margin-right: 11px;
  padding: 16px;
  background-color: var(--color-primary-contrast);
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  box-shadow: var(--form-shadow);
  position: relative;
  overflow: hidden;
}

.customer-identification-form__form--dp7v-:focus-within {
  border-color: var(--color-primary);
}

.customer-identification-form__formDisabled--XtZiU {
  pointer-events: none;
}

.customer-identification-form__title--lXg21 {
  color: var(--color-text);
  font-weight: 500;
  font-size: 20px;
}

.customer-identification-form__fields--nQ60q {
  margin-top: 24px;
}

.customer-identification-form__fields--nQ60q > div + div {
  margin-top: 24px;
}

.customer-identification-form__buttons--n8XwY {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
}

.customer-identification-form__buttons--n8XwY button {
  max-width: 148px;
}

.customer-identification-form__grecaptcha-badge--PXTWw {
  visibility: hidden;
}

.customer-identification-form__grecaptchaLabels--soXZU {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  column-gap: 8px;
}

.customer-identification-form__grecaptchaLabels--soXZU p {
  margin-top: 0;
  color: var(--color-text--lighten);
}

.customer-identification-form__grecaptchaLabels--soXZU p a {
  color: var(--color-primary);
}

.customer-identification-form__grecaptchaRobot--VZDE9 {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/components/customer-identification-form/customer-identification-form.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,+CAA+C;EAC/C,6BAA6B;EAC7B,mCAAmC;EACnC,8BAA8B;EAC9B,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,2BAA2B;AAC7B","sourcesContent":[".form {\n  display: flex;\n  flex-direction: column;\n  margin: 16px;\n  margin-right: 11px;\n  padding: 16px;\n  background-color: var(--color-primary-contrast);\n  border: 1px solid transparent;\n  border-radius: var(--border-radius);\n  box-shadow: var(--form-shadow);\n  position: relative;\n  overflow: hidden;\n}\n\n.form:focus-within {\n  border-color: var(--color-primary);\n}\n\n.formDisabled {\n  pointer-events: none;\n}\n\n.title {\n  color: var(--color-text);\n  font-weight: 500;\n  font-size: 20px;\n}\n\n.fields {\n  margin-top: 24px;\n}\n\n.fields > div + div {\n  margin-top: 24px;\n}\n\n.buttons {\n  margin-top: 16px;\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  column-gap: 8px;\n}\n\n.buttons button {\n  max-width: 148px;\n}\n\n.grecaptcha-badge {\n  visibility: hidden;\n}\n\n.grecaptchaLabels {\n  margin-top: 16px;\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  column-gap: 8px;\n}\n\n.grecaptchaLabels p {\n  margin-top: 0;\n  color: var(--color-text--lighten);\n}\n\n.grecaptchaLabels p a {\n  color: var(--color-primary);\n}\n\n.grecaptchaRobot {\n  margin-top: 16px;\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `customer-identification-form__form--dp7v-`,
	"formDisabled": `customer-identification-form__formDisabled--XtZiU`,
	"title": `customer-identification-form__title--lXg21`,
	"fields": `customer-identification-form__fields--nQ60q`,
	"buttons": `customer-identification-form__buttons--n8XwY`,
	"grecaptcha-badge": `customer-identification-form__grecaptcha-badge--PXTWw`,
	"grecaptchaLabels": `customer-identification-form__grecaptchaLabels--soXZU`,
	"grecaptchaRobot": `customer-identification-form__grecaptchaRobot--VZDE9`
};
export default ___CSS_LOADER_EXPORT___;
