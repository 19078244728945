import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styles from './carousel.module.css';
import 'swiper/css';
import classnames from 'classnames';
import { Nullable } from '@models/nullable.type';
import { CarouselEnum, ICarousel } from '@models/intent/intents.interface';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Stepper } from '@components/stepper';
import { ServiceContext } from '@services/service.provider';
import { BalanceContent } from './balance-content';
import { ImageContent } from './image-content';
import { TextContent } from './text-content';
import { Icon } from '@components/icon';

type Props = {
  carousel: ICarousel;
};

export const Carousel: React.FunctionComponent<Props> = ({ carousel }) => {
  const { twilioConversationsService } = useContext(ServiceContext);
  const [swiper, setSwiper] = useState<Nullable<SwiperCore>>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isStart = currentIndex === 0;
  const isEnd = currentIndex === carousel.items.length - 1;
  const isImageType = carousel.type === CarouselEnum.Image;
  const isTextType = carousel.type === CarouselEnum.Text || carousel.type === CarouselEnum.Balance;

  const onTransitionStartHandler = (s: SwiperCore): void => {
    setCurrentIndex(s.activeIndex);
  };

  const nextHandler = (): void => {
    swiper?.slideNext();
  };

  const prevHandler = (): void => {
    swiper?.slidePrev();
  };

  const clickCardHandler = useCallback(
    (text: string): void => {
      twilioConversationsService.submitUserMessage({ text });
    },
    [twilioConversationsService],
  );

  const renderContent = useCallback((): JSX.Element[] => {
    switch (carousel.type) {
      case CarouselEnum.Balance: {
        return carousel.items.map((item, index) => (
          <SwiperSlide key={index}>
            <BalanceContent
              {...item}
              slideIndex={index}
              PaymentDueDateTextTemplate={carousel.textResources?.paymentDueDateTextTemplate ?? null}
            />
          </SwiperSlide>
        ));
      }
      case CarouselEnum.Image: {
        return carousel.items.map((item, index) => (
          <SwiperSlide key={index}>
            <ImageContent {...item} clickCardHandler={clickCardHandler} slideIndex={index} />
          </SwiperSlide>
        ));
      }
      case CarouselEnum.Text: {
        return carousel.items.map((item, index) => (
          <SwiperSlide key={index}>
            <TextContent {...item} slideIndex={index} />
          </SwiperSlide>
        ));
      }
      default:
        return [];
    }
  }, [carousel.items, carousel.type, clickCardHandler]);
  const carouselRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentSlide =
      typeof swiper?.activeIndex === 'number' &&
      carouselRef.current?.querySelector(`[data-index="${swiper?.activeIndex}"]`);
    const prevSlide =
      typeof swiper?.previousIndex === 'number' &&
      carouselRef.current?.querySelector(`[data-index="${swiper?.previousIndex}"]`);
    if (currentSlide) {
      const tabElements: NodeListOf<HTMLElement> = currentSlide.querySelectorAll('[data-tab-element="true"]');
      [...tabElements].forEach((el) => (el.tabIndex = 0));
    }
    if (prevSlide) {
      const tabElements: NodeListOf<HTMLElement> = prevSlide.querySelectorAll('[data-tab-element="true"]');
      [...tabElements].forEach((el) => (el.tabIndex = -1));
    }
  }, [swiper?.activeIndex, swiper?.previousIndex]);

  return (
    <div ref={carouselRef} className={styles.wrapper} data-qa="carousel_wrapper">
      <div
        role="button"
        onClick={prevHandler}
        className={classnames(styles.arrow, styles.prev, {
          [styles.isBound]: isStart,
          [styles.textArrow]: isTextType,
          [styles.imageArrow]: isImageType,
        })}
        data-qa="carousel_left_button"
        aria-label={`button one of two, button previous, use enter to go to the previous slide, ${
          carousel.type === CarouselEnum.Balance
            ? 'double tab to go to the account name'
            : 'or tab to navigate to the next element'
        }`}
      >
        {isImageType ? <Icon name="arrow-left-light" sizes={[9, 16]} /> : <Icon name="arrow-left" sizes={[12, 10]} />}
      </div>
      <div
        role="button"
        onClick={nextHandler}
        className={classnames(styles.arrow, styles.next, {
          [styles.isBound]: isEnd,
          [styles.textArrow]: isTextType,
          [styles.imageArrow]: isImageType,
        })}
        data-qa="carousel_right_button"
        aria-label={`button two of two, button next, use enter to go to the next slide, ${
          carousel.type === CarouselEnum.Balance ? 'or tab to navigate to the account name' : ''
        }`}
      >
        {isImageType ? <Icon name="arrow-right-light" sizes={[9, 16]} /> : <Icon name="arrow-right" sizes={[12, 10]} />}
      </div>
      <Swiper
        onSwiper={setSwiper}
        slidesPerView={1}
        onTransitionStart={onTransitionStartHandler}
        className={styles.swiper}
      >
        {renderContent()}
      </Swiper>
      <div
        className={classnames({ [styles.textStepper]: isTextType, [styles.imageStepper]: isImageType })}
        data-qa="carousel_stepper"
      >
        <Stepper currentIndex={currentIndex} itemsLength={carousel.items.length} />
      </div>
    </div>
  );
};
