import React, { useContext } from 'react';
import { Button } from '@components/button';
import styles from './resolve.module.css';
import { Icon } from '@components/icon';
import { capitalize } from '@utils/helpers';
import { ServiceContext } from '@services/service.provider';

type Props = {
  canResolveChat: boolean;
  resolveChat(): unknown;
};

export const Resolve: React.FunctionComponent<Props> = ({ canResolveChat, resolveChat }) => {
  const { settingsService } = useContext(ServiceContext);
  return canResolveChat ? (
    <div className={styles.resolve}>
      <Button
        onClick={resolveChat}
        compact
        icon={<Icon name="check-circle" sizes={[24]} />}
        qaLocator="resolve_button"
        ariaLabel={'button, finish chat, pres enter to finish chat'}
      >
        {capitalize(settingsService.settings.LiveChatTextResources.EndChat)}
      </Button>
    </div>
  ) : null;
};
