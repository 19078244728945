// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button__button--JcCKA {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-height: 48px;
  border: none;
  box-shadow: inset 0 0 0 1px var(--color-primary);
  padding: 0 16px;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  background-color: var(--color-primary-contrast);
  color: var(--color-primary);
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.button__compact--5hHUp {
  min-height: 40px;
}

.button__narrow--rf8V5 {
  display: inline-flex;
  width: auto;
  margin: var(--button-spacing);
}

.button__disabled--eiz3H {
  box-shadow: none;
  cursor: default;
  background-color: var(--color-disabled);
  border-color: var(--color-disabled);
}

.button__disabled--eiz3H span {
  color: var(--color-text-secondary);
}

.button__primary--jNDaE:not(.button__disabled--eiz3H) {
  background-color: var(--color-primary);
  color: var(--color-primary-contrast);
}

.button__children--NnAnr {
  display: block;
}

* + .button__children--NnAnr {
  margin-left: 0.3em;
}
`, "",{"version":3,"sources":["webpack://./src/components/button/button.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,gBAAgB;EAChB,YAAY;EACZ,gDAAgD;EAChD,eAAe;EACf,sBAAsB;EACtB,mCAAmC;EACnC,+CAA+C;EAC/C,2BAA2B;EAC3B,eAAe;EACf,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,WAAW;EACX,6BAA6B;AAC/B;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,uCAAuC;EACvC,mCAAmC;AACrC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,sCAAsC;EACtC,oCAAoC;AACtC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".button {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 0;\n  min-height: 48px;\n  border: none;\n  box-shadow: inset 0 0 0 1px var(--color-primary);\n  padding: 0 16px;\n  box-sizing: border-box;\n  border-radius: var(--border-radius);\n  background-color: var(--color-primary-contrast);\n  color: var(--color-primary);\n  font-size: 16px;\n  line-height: 24px;\n  cursor: pointer;\n}\n\n.compact {\n  min-height: 40px;\n}\n\n.narrow {\n  display: inline-flex;\n  width: auto;\n  margin: var(--button-spacing);\n}\n\n.disabled {\n  box-shadow: none;\n  cursor: default;\n  background-color: var(--color-disabled);\n  border-color: var(--color-disabled);\n}\n\n.disabled span {\n  color: var(--color-text-secondary);\n}\n\n.primary:not(.disabled) {\n  background-color: var(--color-primary);\n  color: var(--color-primary-contrast);\n}\n\n.children {\n  display: block;\n}\n\n* + .children {\n  margin-left: 0.3em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button__button--JcCKA`,
	"compact": `button__compact--5hHUp`,
	"narrow": `button__narrow--rf8V5`,
	"disabled": `button__disabled--eiz3H`,
	"primary": `button__primary--jNDaE`,
	"children": `button__children--NnAnr`
};
export default ___CSS_LOADER_EXPORT___;
