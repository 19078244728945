// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/input.module.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/button.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form__form--6yqVW {
  display: flex;
  align-items: center;
  background-color: var(--color-primary-contrast);
}

.form__panel--G1UEn {
  border-top-width: 1px;
  border-top-color: var(--color-chat-divider);
  border-top-style: solid;
}

.form__input--r8dCr {
  flex-grow: 1;
  padding: 0 16px;
  max-height: 100px;
  overflow: auto;
  resize: none;
  background-color: var(--color-primary-contrast);
  color: var(--color-text);
}

.form__input--r8dCr::placeholder {
  color: var(--color-primary);
  opacity: 0.7;
}

.form__input--r8dCr:focus {
  outline: none;
}

.form__button--IpLPn {
  padding: 16px 18px;
  color: var(--color-primary);
}

.form__buttonSubmit--1-ooA {
  padding-right: 8px;
}

.form__buttonMic--prB9e {
  padding-left: 8px;
}

.form__buttonMicActive--chtJN > svg {
  animation: form__micActive--gPqfN infinite 1s;
  opacity: 0.6;
}

.form__button--IpLPn:disabled {
  cursor: default;
  color: var(--color-disabled);
}

.form__button--IpLPn:disabled .form__icon--BZnMY {
  color: var(--color-disabled);
}

.form__button--IpLPn:hover:not(:disabled):not(.form__buttonMicActive--chtJN) .form__icon--BZnMY {
  color: var(--color-primary);
}

@keyframes form__micActive--gPqfN {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/chat/form/form.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,+CAA+C;AACjD;;AAEA;EACE,qBAAqB;EACrB,2CAA2C;EAC3C,uBAAuB;AACzB;;AAEA;EAEE,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,YAAY;EACZ,+CAA+C;EAC/C,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;EAC3B,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EAEE,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,6CAAgC;EAChC,YAAY;AACd;;AAEA;EACE,eAAe;EACf,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".form {\n  display: flex;\n  align-items: center;\n  background-color: var(--color-primary-contrast);\n}\n\n.panel {\n  border-top-width: 1px;\n  border-top-color: var(--color-chat-divider);\n  border-top-style: solid;\n}\n\n.input {\n  composes: input from '~@styles/input.module.css';\n  flex-grow: 1;\n  padding: 0 16px;\n  max-height: 100px;\n  overflow: auto;\n  resize: none;\n  background-color: var(--color-primary-contrast);\n  color: var(--color-text);\n}\n\n.input::placeholder {\n  color: var(--color-primary);\n  opacity: 0.7;\n}\n\n.input:focus {\n  outline: none;\n}\n\n.button {\n  composes: button from '~@styles/button.module.css';\n  padding: 16px 18px;\n  color: var(--color-primary);\n}\n\n.buttonSubmit {\n  padding-right: 8px;\n}\n\n.buttonMic {\n  padding-left: 8px;\n}\n\n.buttonMicActive > svg {\n  animation: micActive infinite 1s;\n  opacity: 0.6;\n}\n\n.button:disabled {\n  cursor: default;\n  color: var(--color-disabled);\n}\n\n.button:disabled .icon {\n  color: var(--color-disabled);\n}\n\n.button:hover:not(:disabled):not(.buttonMicActive) .icon {\n  color: var(--color-primary);\n}\n\n@keyframes micActive {\n  0% {\n    transform: scale(1);\n  }\n  50% {\n    transform: scale(1.3);\n  }\n  100% {\n    transform: scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `form__form--6yqVW`,
	"panel": `form__panel--G1UEn`,
	"input": `form__input--r8dCr ${___CSS_LOADER_ICSS_IMPORT_0___.locals["input"]}`,
	"button": `form__button--IpLPn ${___CSS_LOADER_ICSS_IMPORT_1___.locals["button"]}`,
	"buttonSubmit": `form__buttonSubmit--1-ooA`,
	"buttonMic": `form__buttonMic--prB9e`,
	"buttonMicActive": `form__buttonMicActive--chtJN`,
	"micActive": `form__micActive--gPqfN`,
	"icon": `form__icon--BZnMY`
};
export default ___CSS_LOADER_EXPORT___;
