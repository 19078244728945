import React, { useCallback, useContext, useState } from 'react';
import { IMaskInput } from 'react-imask';
import styles from './custom-input.module.css';
import classNames from 'classnames';
import { capitalize, stripStringByFormat } from '@utils/helpers';
import { StringByFormatEnum } from '@models/helpers';
import { useSayPhrase } from '@hooks/use-say-phrase';
import { ServiceContext } from '@services/service.provider';
import { replaceVariable } from '@utils/debug/replace-variable';

const defaultMaxLength = 100;

type Props = {
  label: string;
  qaLocator?: string;
  qaInputLocator?: string;
  value: string;
  setValue(value: string): void;
  format?: StringByFormatEnum;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mask?: any; // here a mess with lib types :(
  isInvalid?: boolean;
  isRequired?: boolean;
  helpText?: string;
  maxLength?: number;
  className?: string;
  isFormTouched?: boolean;
  autoFocus?: boolean;
};

export const CustomInput: React.FunctionComponent<Props> = ({
  label,
  setValue,
  value,
  placeholder,
  mask = '',
  helpText,
  isInvalid = false,
  isRequired = false,
  maxLength = defaultMaxLength,
  className,
  qaLocator,
  qaInputLocator,
  format,
  isFormTouched,
  autoFocus,
}) => {
  const { settingsService } = useContext(ServiceContext);
  const getErrorMessage = (label: string, isInvalid: boolean, IsRequired: boolean): string => {
    let result = '';

    if (isInvalid) {
      result = settingsService.settings.LiveChatTextResources.InvalidField;
    }
    if (IsRequired) {
      result = replaceVariable(settingsService.settings.LiveChatTextResources.EnterYourLabel, { label });
    }

    return capitalize(result);
  };
  const [isInputTouched, setIsInputTouched] = useState(false);
  const errorMessage = getErrorMessage(label, isInvalid && value.length !== 0, isRequired && value.length === 0);
  const isErrorMessageVisible = errorMessage && (isInputTouched || isFormTouched);
  const [isFocused, setFocused] = useState(false);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => setValueHandler(event.target.value);

  const setValueHandler = useCallback(
    (value: string) => {
      if (!isInputTouched) {
        setIsInputTouched(true);
      }
      setValue(stripStringByFormat(value, format));
    },
    [isInputTouched, setValue, format],
  );

  const focusHandler = (): void => setFocused(true);
  const blurHandler = (): void => setFocused(false);

  const sharedProps: React.InputHTMLAttributes<HTMLInputElement> & { ['data-qa']: string } = {
    type: 'text',
    className: styles.textarea,
    value,
    ['data-qa']: qaInputLocator || `${qaLocator}_input`,
    placeholder,
    maxLength,
    required: isRequired,
    onFocus: focusHandler,
    onBlur: blurHandler,
  };

  const inputProps = {
    ...sharedProps,
    onChange: changeHandler,
    autoFocus,
  };

  const maskInputProps = {
    ...sharedProps,
    onAccept: (value: unknown): void => setValueHandler(value as string),
  };
  useSayPhrase(isErrorMessageVisible && errorMessage ? `error occurred, ${errorMessage}` : '', true);

  return (
    <div className={classNames(styles.wrapper, className)} aria-live="polite" aria-atomic="false" aria-relevant="text">
      <label
        className={classNames(styles.label, {
          [styles.error]: isErrorMessageVisible && isFocused,
          [styles.labelToTop]: isFocused || value,
          [styles.focus]: isFocused,
        })}
        data-qa={qaLocator}
        htmlFor={qaLocator}
      >
        {label}
      </label>
      {mask ? <IMaskInput {...maskInputProps} mask={mask} id={qaLocator} /> : <input {...inputProps} id={qaLocator} />}
      {helpText && <div className={classNames(styles.hint, styles.info)}>{helpText}</div>}
      {isErrorMessageVisible && (
        <div className={classNames(styles.hint, styles.error)} tabIndex={0} aria-label={`error text, ${errorMessage}`}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};
