import React, { useContext } from 'react';
import styles from './message.module.css';
import rootStyles from '@components/root/root.module.css';
import classnames from 'classnames';
import { Message as MessageModel } from '@models/message.model';
import { formatTime } from '@utils/format-time';
import { AuthorEnum } from '@models/author.enum';
import { MessageStatusEnum } from '@models/message-status.enum';
import { ShadowDOM } from '@components/shadow-dom';
import { InView } from 'react-intersection-observer';
import { Nullable } from '@models/nullable.type';
import { Carousel } from '../carousel';
import { SpecialSystemMessageTypes } from '@models/system-message-type.enum';
import { SpecialSystemMessage } from '../special-system-message';
import { Icon } from '@components/icon';
import { useAuthorName } from './hooks/useAuthorName';
import { ServiceContext } from '@services/service.provider';
import { useGoToOFMRoute } from '@hooks/goToOFMRoute.hook';

type Props = {
  message: MessageModel;
  onResend: (message: MessageModel) => void;
  onVisible: (index: number) => void;
  isLast: boolean;
  index: number;
  isSecondMessageFromSpecialist: boolean;
};

export const Message: React.FunctionComponent<Props> = ({
  message,
  onResend,
  onVisible,
  isLast,
  isSecondMessageFromSpecialist,
}) => {
  const isRejected = message.status === MessageStatusEnum.DeliveryFailed;
  const isSystem = message.author === AuthorEnum.System;
  const bubbleClassName = isSystem ? styles.system : classnames(styles.bubble, styles[message.author]);
  const statusClassName = isRejected ? classnames(styles.status, styles.negative) : styles.status;
  const resentClassName = isRejected ? styles.resend : classnames(styles.resend, styles.inactive);
  const { settingsService } = useContext(ServiceContext);
  const { Sending, Delivered, Read, Failed } = settingsService.settings.LiveChatTextResources;

  const handleResend = (): void => {
    if (isRejected) {
      onResend(message);
    }
  };

  const onVisibilityChange = (isInView: boolean): void => {
    if (isInView) {
      onVisible(message.index);
    }
  };

  const renderTick = (): Nullable<JSX.Element> => {
    const isUser = message.author === AuthorEnum.User;
    const isRead = message.status === MessageStatusEnum.Read;
    const isDelivered = message.status === MessageStatusEnum.Delivered;

    if (!isUser) {
      return null;
    }

    if (isRead) {
      return <Icon name="check-read" sizes={[19, 10]} className={styles.read} />;
    } else if (isDelivered) {
      return <Icon name="check-delivered" sizes={[19, 10]} className={styles.read} />;
    } else {
      return null;
    }
  };
  const roleName = useAuthorName(message.author, !!message.attributes?.isManager, message.attributes?.fromName);

  // TODO: Remove this once the hook OFM5 team implements it on those side CCVLC-5677
  //const { handleShadowRootClick } = useGoToOFMRoute();
  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
  const handleShadowRootClick = (): void => {};

  const renderMessageBubble = (textMessage: string, key: number): Nullable<JSX.Element> => {
    if (!textMessage) {
      return (
        <InView as="div" onChange={onVisibilityChange}>
          <div />
        </InView>
      );
    }

    const getMessageStatusText = (messageStatus: MessageStatusEnum): string => {
      const messagesStatusTextByStatus: Record<MessageStatusEnum, string> = {
        [MessageStatusEnum.Read]: Read,
        [MessageStatusEnum.Delivered]: Delivered,
        [MessageStatusEnum.Sending]: Sending,
        [MessageStatusEnum.Failed]: Failed,
      };

      if (!messagesStatusTextByStatus[messageStatus]) {
        return '';
      }

      const status = messagesStatusTextByStatus[messageStatus];
      return status === MessageStatusEnum.Read
        ? messagesStatusTextByStatus[MessageStatusEnum.Delivered]
        : messagesStatusTextByStatus[messageStatus];
    };

    return (
      <div
        tabIndex={-1}
        key={key}
        className={classnames(styles.message, styles[message.author])}
        data-client-id={message.clientId}
        data-qa={`message_type_${message.author}`}
        data-message="message"
      >
        <p className={rootStyles['visually-hidden']} aria-label={`message from ${message.author}..`}></p>
        <InView as="div" className={bubbleClassName} onChange={onVisibilityChange}>
          {!isSecondMessageFromSpecialist && roleName && <div className={styles.name}>{roleName}</div>}
          <ShadowDOM innerHTML={textMessage} qaLocator={'message_shadow_root'} onClick={handleShadowRootClick} />
          {!isSystem && (
            <div className={styles.info}>
              <time className={styles.time}>{formatTime(message.timestamp)}</time>
              {renderTick()}
            </div>
          )}
        </InView>
        {message.isRejected && (
          <div className={statusClassName}>
            <button
              className={resentClassName}
              onClick={handleResend}
              title={isRejected ? 'Resend' : ''}
              aria-label={'button, resend message, press enter to resend message'}
              data-qa="message_resend_button"
              tabIndex={-1}
            >
              {isRejected && <Icon name="reject-circle" sizes={[16]} className={styles.icon} />}
              {message.status && getMessageStatusText(message.status)}
            </button>
          </div>
        )}
      </div>
    );
  };

  if (message.attributes?.type && SpecialSystemMessageTypes.includes(message.attributes.type)) {
    return <SpecialSystemMessage message={message} isLast={isLast} onVisibilityChange={onVisibilityChange} />;
  }

  return (
    <>
      {message.intent.texts.map(renderMessageBubble)}
      {message.intent.carousel?.type && <Carousel carousel={message.intent.carousel} />}
    </>
  );
};
