function hexToRgb(hex: string): { r: number; g: number; b: number } {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

export function getContrastColor(backgroundColor: string): string {
  let color = '';
  if (typeof backgroundColor !== 'string') {
    return color;
  }

  const rgb = hexToRgb(backgroundColor);
  const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
  if (brightness > 130 || brightness === 0) {
    color = '#000000';
  } else {
    color = '#FFFFFF';
  }

  return color;
}
