// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/title.module.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/button.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu__menu--OyW0E {
  padding: 16px;
}

.menu__title--pKx6O {
  color: var(--color-text);
  margin: 8px 0 24px;
}

.menu__button--TfcPK {
  color: var(--color-primary);
  background-color: var(--color-primary-contrast);
  font-size: 16px;
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  border-radius: var(--border-radius);
}

.menu__button--TfcPK:disabled {
  color: var(--color-disabled);
  cursor: default;
}

.menu__button--TfcPK + .menu__button--TfcPK {
  margin-top: 8px;
}

.menu__icon--8lnJ2 {
  margin-right: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/router/menu/menu.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EAEE,wBAAwB;EACxB,kBAAkB;AACpB;;AAEA;EAEE,2BAA2B;EAC3B,+CAA+C;EAC/C,eAAe;EACf,WAAW;EACX,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,aAAa;EACb,mCAAmC;AACrC;;AAEA;EACE,4BAA4B;EAC5B,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".menu {\n  padding: 16px;\n}\n\n.title {\n  composes: title from '~@styles/title.module.css';\n  color: var(--color-text);\n  margin: 8px 0 24px;\n}\n\n.button {\n  composes: button from '~@styles/button.module.css';\n  color: var(--color-primary);\n  background-color: var(--color-primary-contrast);\n  font-size: 16px;\n  width: 100%;\n  text-decoration: none;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 16px;\n  border-radius: var(--border-radius);\n}\n\n.button:disabled {\n  color: var(--color-disabled);\n  cursor: default;\n}\n\n.button + .button {\n  margin-top: 8px;\n}\n\n.icon {\n  margin-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `menu__menu--OyW0E`,
	"title": `menu__title--pKx6O ${___CSS_LOADER_ICSS_IMPORT_0___.locals["title"]}`,
	"button": `menu__button--TfcPK ${___CSS_LOADER_ICSS_IMPORT_1___.locals["button"]}`,
	"icon": `menu__icon--8lnJ2`
};
export default ___CSS_LOADER_EXPORT___;
