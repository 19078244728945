export const localeStorageKey = 'ast.orpheus.app.culture-id';
export const getLocale = (): string => {
  const currentLocale: string = localStorage[localeStorageKey] || navigator.language;
  const supportedLocales = ['es', 'en'];
  const language = currentLocale?.length > 2 ? currentLocale.slice(0, 2) : currentLocale; // en-EN -> en or take es-EN
  const defaultLocale = 'en-EN';
  const locale = supportedLocales.includes(language) ? currentLocale : defaultLocale;

  return locale;
};
