import { LoadingMessage } from '@components/chat/loading-message';
import React, { ChangeEvent, FormEvent, KeyboardEvent, useContext, useEffect, useRef } from 'react';
import { ServiceContext } from '@services/service.provider';
import TextArea from 'react-textarea-autosize';
import styles from './form.module.css';
import classnames from 'classnames';
import { useIsChatOffline } from '../hooks/use-is-chat-offline';
import { useObservable } from '@hooks/use-observable';
import { useFormMessageState, useIsMessageMaxLength, useIsTyping, useSpeachRecognition } from './form.hooks';
import { sendGAEvent } from '@utils/GA';
import { isMobile } from 'react-device-detect';
import { Icon } from '@components/icon';
import { useAppSelector } from '@store';
import { capitalize } from '@utils/helpers';

interface IFormProps {
  isBrowser: boolean;
  isOnPrivacyModal: boolean;
  areMessagesFetching: boolean;
  areNoMessages: boolean;
  turnViewingHistoryOff(): void;
  resetCurrentFocussedMessageIndex(): void;
}

export const Form: React.FunctionComponent<IFormProps> = ({
  isBrowser,
  isOnPrivacyModal,
  areMessagesFetching,
  areNoMessages,
  turnViewingHistoryOff,
  resetCurrentFocussedMessageIndex,
}) => {
  const { twilioConversationsService, liveChatService, settingsService } = useContext(ServiceContext);
  const [message, setMessage] = useFormMessageState();
  const { isLengthExceeded, maxMessageLength } = useIsMessageMaxLength(message);
  const isSubmitDisabled = useObservable(liveChatService.isSubmitDisabled$, false);
  const isInputDisabled = useIsChatOffline() || isOnPrivacyModal;
  const { resetTranscript, isListening, isSpeechRecognitionEnabled, transcript, startListening, stopListening } =
    useSpeachRecognition();
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  useIsTyping(message);

  const isSessionInitializationError = useAppSelector((state) => state.ofm.isError);
  const isSpeachEnabled = isSpeechRecognitionEnabled && isBrowser && !isMobile;

  const submitMessage = (): void => {
    turnViewingHistoryOff();
    twilioConversationsService.submitUserMessage({ text: message.trim() });
    setMessage('');
    resetTranscript();

    sendGAEvent('User', 'Sends a message');
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    submitMessage();
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setMessage(event.target.value);
    resetTranscript();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event?.code === 'Enter' && !event.shiftKey && !isSubmitDisabled) {
      handleSubmit(event);
    }
  };

  const handleClickSpeechRecognition = (event: FormEvent): void => {
    event.preventDefault();

    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
  };

  useEffect(() => {
    if (!isListening && inputRef.current) {
      const timerId = setTimeout(() => {
        inputRef.current?.focus();
        clearTimeout(timerId);
      }, 0);
    }
  }, [isListening]);

  useEffect(() => {
    if (transcript) {
      setMessage(transcript);
    }
  }, [setMessage, transcript]);

  const { TypeAMessage } = settingsService.settings.LiveChatTextResources;

  return (
    <div>
      {!isSessionInitializationError ? (
        <LoadingMessage
          isLengthExceeded={isLengthExceeded}
          isInitMessagesFetching={areNoMessages && areMessagesFetching}
        />
      ) : (
        <div></div>
      )}
      {!isSessionInitializationError ? (
        <div className={styles.panel}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <TextArea
              className={styles.input}
              ref={inputRef}
              placeholder={`${capitalize(TypeAMessage)}...`}
              value={message}
              onChange={handleChange}
              onFocus={resetCurrentFocussedMessageIndex}
              onKeyDown={handleKeyDown}
              maxLength={maxMessageLength}
              disabled={isInputDisabled}
              data-qa="form_message_input"
              aria-label={
                'text input, enter message, use left alt plus arrows to navigate between messages or tab to navigate to the next element'
              }
              autoFocus
            />
            <button
              className={classnames(styles.button, { [styles.buttonSubmit]: isSpeachEnabled })}
              disabled={isSubmitDisabled}
              type="submit"
              data-qa="form_send_button"
              aria-label={'button, send message, press enter to send message'}
              aria-disabled={isSubmitDisabled}
            >
              <Icon name="paper-plane" sizes={[24]} />
            </button>
            {isSpeachEnabled && (
              <button
                className={classnames(styles.button, styles.buttonMic, { [styles.buttonMicActive]: isListening })}
                onClick={handleClickSpeechRecognition}
                disabled={isInputDisabled}
                aria-label={'button, activate microphone, press enter to activate microphone'}
                aria-disabled={isInputDisabled}
              >
                <Icon name="mic" sizes={[24]} />
              </button>
            )}
          </form>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
