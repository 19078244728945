import React, { useContext } from 'react';
import styles from './carousel.module.css';
import { IBalanceItem } from '@models/intent/intents.interface';
import { formatDate } from '@utils/format-date';
import { capitalize } from '@utils/helpers';
import { ServiceContext } from '@services/service.provider';
import { replaceVariable } from '@utils/debug/replace-variable';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { getLocale } from '@utils/get-locale';

type Props = IBalanceItem;

export const BalanceContent: React.FunctionComponent<Props> = ({
  Name,
  Number,
  Type,
  Balance,
  AvailableBalance,
  Loan,
  slideIndex,
  PaymentDueDateTextTemplate,
}) => {
  const { settingsService, sessionStorageService } = useContext(ServiceContext);
  const isLocalizationEnabled = sessionStorageService.get(SessionStorageService.IsLocalizationEnabled);
  const locale = isLocalizationEnabled ? getLocale() : 'en-US';
  const date = Loan?.NextPaymentDate && formatDate(Loan.NextPaymentDate, locale);
  const PaymentDueDate = PaymentDueDateTextTemplate ?? settingsService.settings.LiveChatTextResources.PaymentDueDate;

  return (
    <div className={styles.balanceWrapper} data-qa="carousel_balance_wrapper" data-index={slideIndex}>
      <div
        className={styles.balanceName}
        data-tab-element={true}
        tabIndex={-1}
        aria-label={`heading, ${Name}, use tab to navigate to the next element`}
      >
        {Name}
      </div>
      <span
        className={styles.balanceNumber}
        data-tab-element={true}
        aria-label={`text, balance type ${Type}, balance number ${Number}, use tab to navigate to the next element`}
      >
        {Type} {Number}
      </span>
      <span
        className={styles.availableBalance}
        data-qa="carousel_available_balance"
        data-tab-element={true}
        tabIndex={-1}
        aria-label={`text, balance available: ${AvailableBalance}, use tab to navigate to the next element`}
      >
        {AvailableBalance}
      </span>
      <span
        className={styles.currentBalance}
        data-qa="carousel_balance"
        data-tab-element={true}
        aria-label={`text, balance value: ${Balance}, use tab to navigate to the next element`}
      >
        {Balance}
      </span>
      {!!date && (
        <span
          data-qa="carousel_next_payment_amount"
          className={styles.nextPaymentAmount}
          data-tab-element={true}
          tabIndex={-1}
          aria-label={`text, next payment due date: ${date}, use tab to navigate to the next element`}
          style={{
            color: Loan?.IsOverdueInPaymentAmount ? 'var(--color-warning)' : 'var(--color-text-secondary)',
          }}
        >
          {capitalize(replaceVariable(PaymentDueDate, { date }))}
        </span>
      )}
    </div>
  );
};
