import '@styles/variables.css';
import sprite from '@icons/sprite.svg';
import { initApp } from './app';
import { styleTags } from '@utils/style-loader';
import { requestLangFromHostPage, subscribeToLangRequest, subscribeToLangResponse } from '@utils/post-iframe-lang';

// this node is appended to the target document
const appRootNode = document.createElement('div');

appRootNode.style.position = 'fixed';
appRootNode.style.right = '16px';
appRootNode.style.bottom = '16px';
// this dirty hack ensures our chat app is over another elements
appRootNode.style.zIndex = '98';
appRootNode.dataset.qa = 'application_root';
appRootNode.id = 'live_chat_application_root';
document.body.appendChild(appRootNode);
// our application is running in shadow DOM
// and attached to appRootNode for style isolation
// styleTags is created and gathered by webpack via gatherStyles utility
const shadowRoot = appRootNode.attachShadow({ mode: 'open' });
const reactContainerNode = document.createElement('div');
const cssClass = 'livechat-root-node';
reactContainerNode.classList.add(cssClass);
const hasHiddenAttribute: boolean =
  document.currentScript?.dataset?.mobileIconHidden && JSON.parse(document.currentScript?.dataset?.mobileIconHidden);
function createSVGSprite(): HTMLDivElement {
  const node = document.createElement('div');
  node.style.position = 'absolute';
  node.style.width = '0';
  node.style.height = '0';
  node.hidden = true;
  node.innerHTML = sprite;
  return node;
}

subscribeToLangRequest();
subscribeToLangResponse();
requestLangFromHostPage();

shadowRoot.append(createSVGSprite(), ...styleTags, reactContainerNode);
const isBrowser = !document.currentScript?.dataset.webView;

initApp(reactContainerNode, isBrowser, hasHiddenAttribute);
