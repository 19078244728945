// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-underlay__underlay--TsG4v {
  position: relative;
  padding-bottom: 16px;
}

.icon-underlay__icon--fZWHT {
  position: relative;
  margin: auto;
  width: 72px;
  height: 72px;
  fill: var(--color-primary);
}

.icon-underlay__compact--0cUvk {
  padding-bottom: 8px;
}

.icon-underlay__compact--0cUvk::before {
  height: 32px;
}

.icon-underlay__compact--0cUvk .icon-underlay__icon--fZWHT {
  width: 48px;
  height: 48px;
}

.icon-underlay__neutral--fGhxG {
  color: var(--color-neutral);
}
.icon-underlay__neutral--fGhxG:before {
  background-color: var(--color-neutral);
}

.icon-underlay__info--9uqKc {
  color: var(--color-info);
}
.icon-underlay__info--9uqKc:before {
  background-color: var(--color-info);
}

.icon-underlay__positive--5yFFY {
  color: var(--color-positive);
}
.icon-underlay__positive--5yFFY:before {
  background-color: var(--color-positive);
}

.icon-underlay__notice--cvN7E {
  color: var(--color-notice);
}
.icon-underlay__notice--cvN7E:before {
  background-color: var(--color-notice);
}

.icon-underlay__negative--GWk23 {
  color: var(--color-warning);
}
.icon-underlay__negative--GWk23:before {
  background-color: var(--color-warning);
}

.icon-underlay__disabled--wUjbk {
  color: var(--color-disabled);
}
.icon-underlay__disabled--wUjbk:before {
  background-color: var(--color-disabled);
}
`, "",{"version":3,"sources":["webpack://./src/components/icon-underlay/icon-underlay.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,2BAA2B;AAC7B;AACA;EACE,sCAAsC;AACxC;;AAEA;EACE,wBAAwB;AAC1B;AACA;EACE,mCAAmC;AACrC;;AAEA;EACE,4BAA4B;AAC9B;AACA;EACE,uCAAuC;AACzC;;AAEA;EACE,0BAA0B;AAC5B;AACA;EACE,qCAAqC;AACvC;;AAEA;EACE,2BAA2B;AAC7B;AACA;EACE,sCAAsC;AACxC;;AAEA;EACE,4BAA4B;AAC9B;AACA;EACE,uCAAuC;AACzC","sourcesContent":[".underlay {\n  position: relative;\n  padding-bottom: 16px;\n}\n\n.icon {\n  position: relative;\n  margin: auto;\n  width: 72px;\n  height: 72px;\n  fill: var(--color-primary);\n}\n\n.compact {\n  padding-bottom: 8px;\n}\n\n.compact::before {\n  height: 32px;\n}\n\n.compact .icon {\n  width: 48px;\n  height: 48px;\n}\n\n.neutral {\n  color: var(--color-neutral);\n}\n.neutral:before {\n  background-color: var(--color-neutral);\n}\n\n.info {\n  color: var(--color-info);\n}\n.info:before {\n  background-color: var(--color-info);\n}\n\n.positive {\n  color: var(--color-positive);\n}\n.positive:before {\n  background-color: var(--color-positive);\n}\n\n.notice {\n  color: var(--color-notice);\n}\n.notice:before {\n  background-color: var(--color-notice);\n}\n\n.negative {\n  color: var(--color-warning);\n}\n.negative:before {\n  background-color: var(--color-warning);\n}\n\n.disabled {\n  color: var(--color-disabled);\n}\n.disabled:before {\n  background-color: var(--color-disabled);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"underlay": `icon-underlay__underlay--TsG4v`,
	"icon": `icon-underlay__icon--fZWHT`,
	"compact": `icon-underlay__compact--0cUvk`,
	"neutral": `icon-underlay__neutral--fGhxG`,
	"info": `icon-underlay__info--9uqKc`,
	"positive": `icon-underlay__positive--5yFFY`,
	"notice": `icon-underlay__notice--cvN7E`,
	"negative": `icon-underlay__negative--GWk23`,
	"disabled": `icon-underlay__disabled--wUjbk`
};
export default ___CSS_LOADER_EXPORT___;
