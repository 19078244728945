// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-button-group__wrapper--Dy6UQ {
}

.radio-button-group__container--BSbkp {
  position: relative;
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid var(--color-text--lighten);
  color: var(--color-text);
}

.radio-button-group__container--BSbkp input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-button-group__checkmark--MLve8 {
  position: absolute;
  top: 16px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: var(--color-primary-contrast);
  border-radius: 50%;
  border: 2px solid var(--color-text--lighten);
}

.radio-button-group__container--BSbkp input:checked ~ .radio-button-group__checkmark--MLve8 {
  background-color: var(--color-primary);
}

.radio-button-group__checkmark--MLve8:after {
  content: '';
  position: absolute;
  display: none;
}

.radio-button-group__container--BSbkp input:checked ~ .radio-button-group__checkmark--MLve8:after {
  display: block;
}

.radio-button-group__container--BSbkp .radio-button-group__checkmark--MLve8:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--color-primary-contrast);
}
`, "",{"version":3,"sources":["webpack://./src/components/radio-button-group/radio-button-group.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,sBAAsB;EACtB,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,mDAAmD;EACnD,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,YAAY;EACZ,WAAW;EACX,+CAA+C;EAC/C,kBAAkB;EAClB,4CAA4C;AAC9C;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yCAAyC;AAC3C","sourcesContent":[".wrapper {\n}\n\n.container {\n  position: relative;\n  height: 56px;\n  display: flex;\n  align-items: center;\n  padding-left: 35px;\n  cursor: pointer;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  touch-action: none;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  border-bottom: 1px solid var(--color-text--lighten);\n  color: var(--color-text);\n}\n\n.container input {\n  position: absolute;\n  opacity: 0;\n  cursor: pointer;\n}\n\n.checkmark {\n  position: absolute;\n  top: 16px;\n  left: 0;\n  height: 20px;\n  width: 20px;\n  background-color: var(--color-primary-contrast);\n  border-radius: 50%;\n  border: 2px solid var(--color-text--lighten);\n}\n\n.container input:checked ~ .checkmark {\n  background-color: var(--color-primary);\n}\n\n.checkmark:after {\n  content: '';\n  position: absolute;\n  display: none;\n}\n\n.container input:checked ~ .checkmark:after {\n  display: block;\n}\n\n.container .checkmark:after {\n  top: 5px;\n  left: 5px;\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background: var(--color-primary-contrast);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `radio-button-group__wrapper--Dy6UQ`,
	"container": `radio-button-group__container--BSbkp`,
	"checkmark": `radio-button-group__checkmark--MLve8`
};
export default ___CSS_LOADER_EXPORT___;
