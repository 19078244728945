import { ChatModeEnum } from '@models/chat-mode.enum';
import { IBrandingSettings } from './branding';
import { LogLevelEnum } from '@api/ILogRequest';
import { IPrivacyPolicySettings as IPrivacyPolicySettings } from './privacy';
import { Nullable } from '@models/nullable.type';
import { ILiveChatTextResources } from '@api/settings/live-chat-text-resources';

export interface ILivechatSettings {
  GoogleAnalyticMeasurementId: string;
  AnonymousInitializingMessage: string;
  InternetConnectionErrorMessage: string;
  BackendErrorMessage: string;
  IsSpeachRecognitionEnabled: boolean;
  MaxMessageLength: number;
  SearchingAvailableAgentStatusMessage: string;
  RatingSettings: {
    IsEnabled: boolean;
    Questions: ILivechatSettingsQuestion[];
    RateMessages: never[];
  };
  RecaptchaSettings: {
    RecaptchaIsEnabled: boolean;
    RecaptchaV3SiteKey: string;
    RecaptchaV2SiteKey: string;
  };
  FollowUpSettings: unknown;
  IsSkillsEnabled: boolean;
  AnonymCustomerContactInfo: IAnonymCustomerContactInfoSettings;
  AnonymChatOnlineWelcomeMessage: IWelcomeMessage;
  AnonymChatOfflineWelcomeMessage: IWelcomeMessage;
  AuthSettings: IAuthSettings;
  AnonymMemberNumberSettings: { MinLength: number; MaxLength: number };
  BrandingSettings: IBrandingSettings;
  Mode: ChatModeEnum;
  LogSettings: ILogSettings;
  GracePeriodSeconds: number;
  PrivacyPolicySettings: IPrivacyPolicySettings;
  AnonymCustomerChoosePhoneSettings: IAnonymCustomerChoosePhoneSettings;
  LiveChatMenuButton: IMenuButton;
  LiveChatTextResources: ILiveChatTextResources;
  ContactUsMenuButton: IMenuButtonEmail;
  PhoneUsMenuButton: IMenuButtonPhone;
  BotCopyMenuButton: IMenuButton;
  LiveChatBotMenuButton: IMenuButton;
  AgentSearchMessage: string;
  Header: string;
  Greeting: string;
  LiveChatHeader: string;
  PagesWithoutLiveChat: string[];
  AnonymCustomerOtpSettings: IAnonymCustomerOtpSettings;
  AssetsSettings: IAssetsSettings;
  UseIntentsForLiveChat: boolean;
  UnexpectedErrorMessage: string;
  NoInternetMessage: string;
  LiveChatButtonSettings: ILiveChatButtonSettings;
  LiveChatNameSettings: ILiveChatNameSettings;
}

export interface ILivechatSettingsQuestion {
  Question: string;
  Rates: ILivechatSettingsRatingConfig[];
}

export interface ILivechatSettingsRatingConfig {
  Rate: number;
  Question: string;
}

export enum SsnFormatEnum {
  Full = 0,
  FourDigits = 1,
}

type IFormFieldSettings = { IsVisible: boolean; IsRequired: boolean; Format?: SsnFormatEnum };

type IFormMemberNumberFieldSettings = IFormFieldSettings & { Label: string };

export interface IAuthSettings {
  Email: IFormFieldSettings;
  FirstName: IFormFieldSettings;
  LastName: IFormFieldSettings;
  MemberNumber: IFormMemberNumberFieldSettings;
  PhoneNumber: IFormFieldSettings;
  RejectMessage: string;
  RequestMessage: string;
  ResponseMessage: string;
  Ssn: IFormFieldSettings;
  Title: string;
}

export interface IAnonymCustomerContactInfoSettings {
  RequestMessage: string;
  ResponseMessage: string;
  WelcomeMessage: string;
  Title: string;
  ClientErrorMessage: string;
  NameIsEnabled: boolean;
  PhoneIsEnabled: boolean;
  EmailIsEnabled: boolean;
  InfoIsEnabled: boolean;
  MaxDataLength: number;
  MemberNumberLabel: string;
}

interface IAnonymCustomerChoosePhoneSettings {
  RejectMessage: string;
  RequestMessage: string;
  ResponseMessage: string;
  Title: string;
  WelcomeMessage: string;
}

export interface IWelcomeMessage {
  Title: string;
  Text: string;
}

export interface ILogSettings {
  level: LogLevelEnum;
}

export interface IMenuButton {
  IsVisible: boolean;
  Title: string;
}

export interface IMenuButtonPhone extends IMenuButton {
  Phone: string;
}

export interface IMenuButtonEmail extends IMenuButton {
  Email: string;
}

export interface IAnonymCustomerOtpSettings {
  Title: string;
  Message: string;
  ResendCodeLabel: string;
  DidntReceiveCodeLabel: string;
}

export interface IAssetsSettings {
  ChatBotImageUrl: Nullable<string>;
  LiveChatImageUrl: Nullable<string>;
  OfflineImageUrl: Nullable<string>;
  OpenChatImageUrl: Nullable<string>;
}

export interface ILiveChatButtonSettings {
  LiveChatButtonText: string;
  LiveChatButtonAlertAnimationTimeout: number;
  IsLiveChatButtonDraggableForMobile: boolean;
}

export enum LiveChatNameSettingsModeEnum {
  NoNames = 0,
  RealNames = 1,
  CustomNames = 2,
}

export interface ILiveChatNameSettings {
  Mode: LiveChatNameSettingsModeEnum;
  ManagerRoleName: string;
  AgentRoleName: string;
}
