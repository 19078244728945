import { useContext, useMemo } from 'react';
import { ServiceContext } from '@services/service.provider';
import { capitalize } from '@utils/helpers';
import { AuthorEnum } from '@models/author.enum';
import { Nullable } from '@models/nullable.type';
import { LiveChatNameSettingsModeEnum } from '@api/settings';

/**
 * Hook returns the name of the role for agent/manager based on the settings configuration.
 * Agent/Manager names are by default, if custom names are used it will be changed accordingly,
 * if the real names are chosen as the name of the specialist, it will be shown
 * @param author {string} the author of the message
 * @param isManager {boolean} if the author is a manager
 * @param fromName {string} first name and last name of agent/manager
 * @return role {string} - role name of the agent/manager
 */

export const useAuthorName = (author: string, isManager: boolean, fromName: Nullable<string> | undefined): string => {
  const { settingsService } = useContext(ServiceContext);
  const {
    LiveChatNameSettings,
    LiveChatTextResources: { Manager, Agent },
  } = settingsService.settings;
  const roleName = useMemo((): string => {
    let role = '';

    const hasNoNameRole = LiveChatNameSettings.Mode === LiveChatNameSettingsModeEnum.NoNames;
    const isAuthorAgentOrManager = author === AuthorEnum.Specialist || isManager;
    if (hasNoNameRole || !isAuthorAgentOrManager) {
      return role;
    }
    const setDefaultNames = (): void => {
      if (isManager) {
        role = capitalize(Manager);
      } else if (author === AuthorEnum.Specialist) {
        role = capitalize(Agent);
      }
    };

    switch (LiveChatNameSettings.Mode) {
      case LiveChatNameSettingsModeEnum.RealNames:
        if (!fromName) {
          console.warn('fromName should be set!');
          setDefaultNames();
        } else {
          role = fromName;
        }
        break;
      case LiveChatNameSettingsModeEnum.CustomNames:
        if (!LiveChatNameSettings?.ManagerRoleName || !LiveChatNameSettings?.AgentRoleName) {
          console.warn('ManagerRoleName and AgentRoleName should be set!');
          setDefaultNames();
        } else {
          if (isManager) {
            role = capitalize(LiveChatNameSettings.ManagerRoleName);
          } else if (author === AuthorEnum.Specialist) {
            role = capitalize(LiveChatNameSettings.AgentRoleName);
          }
        }
        break;
    }
    return role;
  }, [LiveChatNameSettings, author, fromName, isManager, Agent, Manager]);

  return roleName;
};
