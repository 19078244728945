// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-message__root--Gbpod {
  position: relative;
  padding-bottom: 4px;
  padding-top: 4px;
  padding-left: 16px;
  color: var(--color-background-contrast);
  opacity: 0.7;
  font-size: 14px;
  line-height: 24px;
  min-height: 24px;
}

.loading-message__root--Gbpod:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  top: 0;
  left: 0;
  background-color: var(--color-chat-divider);
}

.loading-message__error--Lpm-7 {
  color: var(--color-warning);
}
`, "",{"version":3,"sources":["webpack://./src/components/chat/loading-message/loading-message.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,uCAAuC;EACvC,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,WAAW;EACX,MAAM;EACN,OAAO;EACP,2CAA2C;AAC7C;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".root {\n  position: relative;\n  padding-bottom: 4px;\n  padding-top: 4px;\n  padding-left: 16px;\n  color: var(--color-background-contrast);\n  opacity: 0.7;\n  font-size: 14px;\n  line-height: 24px;\n  min-height: 24px;\n}\n\n.root:after {\n  position: absolute;\n  content: '';\n  width: 100%;\n  height: 1px;\n  top: 0;\n  left: 0;\n  background-color: var(--color-chat-divider);\n}\n\n.error {\n  color: var(--color-warning);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `loading-message__root--Gbpod`,
	"error": `loading-message__error--Lpm-7`
};
export default ___CSS_LOADER_EXPORT___;
