import { Loader } from '@components/loader';
import classnames from 'classnames';
import React, { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { Input } from '@ast/magma/components/input';
import { timeOutResponseInSeconds } from '../../../consts';
import styles from './contact-info.module.css';
import { InfoMessage } from '@components/info-message';
import { Nullable } from '@models/nullable.type';
import { ServiceContext } from '@services/service.provider';
import { AbstractStatusEnum } from '@models/abstract-status.enum';
import { capitalize, stripStringByFormat } from '@utils/helpers';
import { StringByFormatEnum } from '@models/helpers';
import { IContactInfo } from '@services/livechat/contact-info';
import { CustomInput } from '@components/custom-input';
import { useVoiceForLabels } from '@hooks/use-voice-for-labels';

interface IProps {
  savedContactInfo: Nullable<IContactInfo>;
  isLast: boolean;
}

// TODO unify Ratings and ContactInfo forms. Now they process submit errors and closing differently
export const ContactInfo: React.FunctionComponent<IProps> = ({ savedContactInfo, isLast }) => {
  const { liveChatService, settingsService } = useContext(ServiceContext);
  const contactForm = liveChatService.savedContactInfoForm ? liveChatService.savedContactInfoForm : savedContactInfo;
  const titleRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');
  const initialInfoSettled = useRef(false);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [note, setNote] = useState('');
  const formRef = useVoiceForLabels();
  const timerId = useRef(0);

  useEffect(() => {
    if (!initialInfoSettled.current && contactForm) {
      initialInfoSettled.current = true;
      setFirstName(contactForm.firstName || '');
      setLastName(contactForm.lastName || '');
      setPhone(contactForm.phone || '');
      setEmail(contactForm.email || '');
      setNote(contactForm.info || '');
    }
  }, [contactForm]);

  useEffect(() => {
    if (firstName || lastName || phone || email || note) {
      liveChatService.savedContactInfoForm = { firstName, lastName, phone, email, info: note };
    } else {
      liveChatService.savedContactInfoForm = null;
    }
  }, [email, firstName, lastName, liveChatService, note, phone]);

  useEffect(() => {
    if (isLast) {
      setTimeout(() => {
        if (titleRef.current) {
          titleRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
    }
  }, [isLast]);

  const contactInfo = settingsService.settings.AnonymCustomerContactInfo;

  const defaultToNull = (item: string): Nullable<string> => {
    return item.length > 0 ? item : null;
  };

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    if (isDisabled) {
      return;
    }

    setMessage('');
    setIsLoading(true);

    const response = await liveChatService.submitContactInfo({
      firstName: defaultToNull(firstName),
      lastName: defaultToNull(lastName),
      email: defaultToNull(email),
      phone: phone ? stripStringByFormat(phone, StringByFormatEnum.Digits) : null,
      info: defaultToNull(note),
    });

    if (response.Error) {
      setMessage(response.Error.Message);
      setIsLoading(false);
    } else {
      liveChatService.savedContactInfoForm = null;
      timerId.current = window.setTimeout(() => {
        setIsLoading(false);
      }, timeOutResponseInSeconds);
    }
  };

  const isFormEmpty = !firstName && !lastName && !email && !phone && !note;
  const isDisabled = isLoading || isFormEmpty;
  const { FirstName, LastName, Email, Phone, AdditionalInformation, Submit } =
    settingsService.settings.LiveChatTextResources;
  const getButtonAriaLabel = (): string => {
    let phrase = 'button, submit contact form';
    phrase += isDisabled
      ? 'button is disabled, please go back to the inputs and set correct information, use shift + tab to navigate'
      : 'press enter to submit contact form';
    if (isLoading) {
      phrase = '';
    }

    return phrase;
  };

  useEffect(
    () => (): void => {
      if (timerId.current) {
        window.clearTimeout(timerId.current);
      }
    },
    [],
  );

  return contactInfo ? (
    <>
      <div ref={titleRef} />
      <form
        ref={formRef}
        className={classnames(styles.form, { [styles.formDisabled]: isLoading })}
        onSubmit={handleSubmit}
      >
        <Loader isLoading={isLoading} />
        <div
          className={styles.title}
          data-qa="contact_info_title"
          tabIndex={0}
          aria-label={`form heading, ${contactInfo.Title}, use tab to navigate to the next element`}
        >
          {contactInfo.Title}
        </div>
        <div
          className={styles.body}
          data-qa="contact_info_welcome_message"
          tabIndex={0}
          aria-label={`form sub-heading, ${contactInfo.WelcomeMessage}, use tab to navigate to the next element`}
        >
          {contactInfo.WelcomeMessage}
        </div>
        <div className={styles.fields}>
          {contactInfo.NameIsEnabled && (
            <>
              <div className={styles.item}>
                <CustomInput
                  label={capitalize(FirstName)}
                  value={firstName}
                  setValue={setFirstName}
                  qaLocator="contact_info_first_name_title"
                  qaInputLocator="contact_info_first_name_input"
                  maxLength={contactInfo.MaxDataLength}
                />
              </div>
              <div className={styles.item}>
                <CustomInput
                  label={capitalize(LastName)}
                  value={lastName}
                  setValue={setLastName}
                  qaLocator="contact_info_last_name_title"
                  qaInputLocator="contact_info_last_name_input"
                  maxLength={contactInfo.MaxDataLength}
                />
              </div>
            </>
          )}
          {contactInfo.EmailIsEnabled && (
            <div className={styles.item}>
              <CustomInput
                label={capitalize(Email)}
                value={email}
                setValue={setEmail}
                qaLocator="contact_info_email_title"
                qaInputLocator="contact_info_email_input"
                maxLength={contactInfo.MaxDataLength}
              />
            </div>
          )}
          {contactInfo.PhoneIsEnabled && (
            <div className={styles.item}>
              <CustomInput
                label={capitalize(Phone)}
                value={phone}
                mask="(000) 000-0000"
                setValue={setPhone}
                qaLocator="contact_info_phone_title"
                qaInputLocator="contact_info_phone_input"
                maxLength={contactInfo.MaxDataLength}
              />
            </div>
          )}
          {contactInfo.InfoIsEnabled && (
            <div data-qa="contact_info_additional_information" className={styles.item}>
              <Input
                label={capitalize(AdditionalInformation)}
                className={styles.textarea}
                value={note}
                onChange={(e): void => setNote(e.target.value)}
                maxLength={contactInfo.MaxDataLength}
                multiline
              />
            </div>
          )}
        </div>
        {message && <InfoMessage type={AbstractStatusEnum.negative} message={message} />}
        <button
          className={classnames(styles.button, styles.primary)}
          aria-disabled={isDisabled}
          type="submit"
          data-qa="contact_info_submit_button"
          aria-label={getButtonAriaLabel()}
        >
          {capitalize(Submit)}
        </button>
      </form>
    </>
  ) : null;
};
