// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/title.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status__status--1rRkh {
  margin-bottom: 16px;
  padding: 16px;
  border-radius: var(--border-radius);
  background-color: var(--color-primary-contrast);
  text-align: center;
}

.status__title--0CC5y {
  margin: 16px 0 8px;
}

.status__text--YFxpI {
  font-size: 14px;
  line-height: 22px;
  color: var(--color-text--lighten);
  text-align: left;
}

.status__centered--0GQzV {
  text-align: center;
}

.status__text--YFxpI ol,
.status__text--YFxpI ul {
  margin-top: 0;
  margin-bottom: 0.62em;
  padding-left: 1.5em;
}

.status__text--YFxpI p {
  margin-top: 0;
  margin-bottom: 0.62em;
}

.status__text--YFxpI *:last-child {
  margin-bottom: 0;
}

.status__text--YFxpI strong,
.status__text--YFxpI b {
  font-weight: bolder;
}

.status__text--YFxpI em,
.status__text--YFxpI i {
  font-style: italic;
}

.status__text--YFxpI u {
  text-decoration: underline;
}

.status__text--YFxpI strike,
.status__text--YFxpI s {
  text-decoration: line-through;
}
`, "",{"version":3,"sources":["webpack://./src/components/chat/status/status.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,mCAAmC;EACnC,+CAA+C;EAC/C,kBAAkB;AACpB;;AAEA;EAEE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,iCAAiC;EACjC,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;EAEE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;;EAEE,6BAA6B;AAC/B","sourcesContent":[".status {\n  margin-bottom: 16px;\n  padding: 16px;\n  border-radius: var(--border-radius);\n  background-color: var(--color-primary-contrast);\n  text-align: center;\n}\n\n.title {\n  composes: title from '~@styles/title.module.css';\n  margin: 16px 0 8px;\n}\n\n.text {\n  font-size: 14px;\n  line-height: 22px;\n  color: var(--color-text--lighten);\n  text-align: left;\n}\n\n.centered {\n  text-align: center;\n}\n\n.text ol,\n.text ul {\n  margin-top: 0;\n  margin-bottom: 0.62em;\n  padding-left: 1.5em;\n}\n\n.text p {\n  margin-top: 0;\n  margin-bottom: 0.62em;\n}\n\n.text *:last-child {\n  margin-bottom: 0;\n}\n\n.text strong,\n.text b {\n  font-weight: bolder;\n}\n\n.text em,\n.text i {\n  font-style: italic;\n}\n\n.text u {\n  text-decoration: underline;\n}\n\n.text strike,\n.text s {\n  text-decoration: line-through;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `status__status--1rRkh`,
	"title": `status__title--0CC5y ${___CSS_LOADER_ICSS_IMPORT_0___.locals["title"]}`,
	"text": `status__text--YFxpI`,
	"centered": `status__centered--0GQzV`
};
export default ___CSS_LOADER_EXPORT___;
