// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes resolve__fadeIn--Z75jy {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.resolve__resolve--zQ8Z- {
  background-color: var(--color-background);
  padding: 16px 24px;
  animation: resolve__fadeIn--Z75jy 0.5s;
  position: relative;
}

.resolve__resolve--zQ8Z-:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--color-chat-divider);
}

.resolve__resolve--zQ8Z- button:hover {
  transition: all 0.3s ease-in-out;
  color: var(--color-primary-contrast);
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
`, "",{"version":3,"sources":["webpack://./src/components/chat/resolve/resolve.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,yCAAyC;EACzC,kBAAkB;EAClB,sCAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,WAAW;EACX,SAAS;EACT,OAAO;EACP,2CAA2C;AAC7C;;AAEA;EACE,gCAAgC;EAChC,oCAAoC;EACpC,sCAAsC;EACtC,kCAAkC;AACpC","sourcesContent":["@keyframes fadeIn {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n.resolve {\n  background-color: var(--color-background);\n  padding: 16px 24px;\n  animation: fadeIn 0.5s;\n  position: relative;\n}\n\n.resolve:after {\n  position: absolute;\n  content: '';\n  width: 100%;\n  height: 1px;\n  bottom: 0;\n  left: 0;\n  background-color: var(--color-chat-divider);\n}\n\n.resolve button:hover {\n  transition: all 0.3s ease-in-out;\n  color: var(--color-primary-contrast);\n  background-color: var(--color-primary);\n  border-color: var(--color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resolve": `resolve__resolve--zQ8Z-`,
	"fadeIn": `resolve__fadeIn--Z75jy`
};
export default ___CSS_LOADER_EXPORT___;
