import { Nullable } from '@models/nullable.type';
import { JSONObject } from '@twilio/conversations';

export interface ITextIntent {
  Text: {
    Text_: string[];
  };
}

export interface ISuggestionIntent {
  Suggestions: {
    Suggestions_: ISuggestion[];
  };
}

export interface ISuggestion {
  Title: string;
}

export interface ICarouselIntentItem {
  Description: string;
  Image: { ImageUri: string; AccessibilityText: string };
  Info: { Key: string; Synonyms: string[] };
  Title: string;
}

export enum CarouselEnum {
  Balance = 'balance',
  Image = 'image',
  Text = 'text',
}

export interface ITextResources extends JSONObject {
  paymentDueDateTextTemplate: string;
}

export interface IBalanceItem extends JSONObject {
  Name: string;
  Number: string;
  Type: string;
  AvailableBalance: string;
  Balance: string;
  Loan: Nullable<{
    PaymentAmount: string;
    OverduePaymentAmount: string;
    NextPaymentDate: string;
    IsOverdueInPaymentAmount: boolean;
  }>;

  slideIndex: number;
  PaymentDueDateTextTemplate: Nullable<string>;
}
export interface ICarouselItem {
  title: string;
  description: string;
  imageUri: string;
  clickMessage: string;
}

export interface ICarouselIntent {
  CarouselSelect: {
    Items: ICarouselIntentItem[];
  };
}

export interface ICarouselBase {
  type: CarouselEnum;
}

interface IImageCarousel extends ICarouselBase {
  type: CarouselEnum.Image;
  items: ICarouselItem[];
}

interface ITextCarousel extends ICarouselBase {
  type: CarouselEnum.Text;
  items: ICarouselItem[];
}

interface IBalanceCarousel extends ICarouselBase {
  type: CarouselEnum.Balance;
  items: IBalanceItem[];
  textResources: Nullable<ITextResources>;
}

export type ICarousel = IImageCarousel | IBalanceCarousel | ITextCarousel;
