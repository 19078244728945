// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select__templatesField--bxpCV {
  position: relative;
}

.select__dropdown--B83V- {
  width: 100%;
  position: absolute;
  height: fit-content;
  max-height: 0;
  background-color: var(--color-primary-contrast);
  box-shadow: var(--form-shadow);
  border-radius: var(--border-radius);
  overflow: auto;
  z-index: 1;
}

.select__isOpened--aqDgR {
  max-height: 240px;
}

.select__field--6asJj > div[class*='inputContainer'] {
  display: flex;
  width: 100%;
}

.select__input--A3sVD {
  max-width: 100%;
  width: 100%;
  cursor: pointer;
  background-color: var(--color-primary-contrast);
}

.select__input--A3sVD input {
  cursor: pointer;
  padding-right: 40px;
  color: var(--color-text);
}

.select__listItem--WrY8t {
  position: relative;
}

.select__listItem--WrY8t:not(.select__listItem--WrY8t:last-child) {
  border-bottom: 1px solid var(--color-text-secondary);
}

.select__listItemBackground--t0hCE {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.select__listItemBackground--t0hCE:hover:not(.select__disabled--X6EJD) {
  background-color: var(--color-primary);
  opacity: 0.16;
}

.select__selected--fDEyw {
  background-color: var(--color-primary);
  opacity: 0.16;
}

.select__disabled--X6EJD {
  background-color: var(--color-grey-700);
  opacity: 0.16;
}

.select__option--zvOCQ {
  padding: 12px 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/select/select.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,+CAA+C;EAC/C,8BAA8B;EAC9B,mCAAmC;EACnC,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;EACX,eAAe;EACf,+CAA+C;AACjD;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,MAAM;EACN,SAAS;AACX;;AAEA;EACE,sCAAsC;EACtC,aAAa;AACf;;AAEA;EACE,sCAAsC;EACtC,aAAa;AACf;;AAEA;EACE,uCAAuC;EACvC,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".templatesField {\n  position: relative;\n}\n\n.dropdown {\n  width: 100%;\n  position: absolute;\n  height: fit-content;\n  max-height: 0;\n  background-color: var(--color-primary-contrast);\n  box-shadow: var(--form-shadow);\n  border-radius: var(--border-radius);\n  overflow: auto;\n  z-index: 1;\n}\n\n.isOpened {\n  max-height: 240px;\n}\n\n.field > div[class*='inputContainer'] {\n  display: flex;\n  width: 100%;\n}\n\n.input {\n  max-width: 100%;\n  width: 100%;\n  cursor: pointer;\n  background-color: var(--color-primary-contrast);\n}\n\n.input input {\n  cursor: pointer;\n  padding-right: 40px;\n  color: var(--color-text);\n}\n\n.listItem {\n  position: relative;\n}\n\n.listItem:not(.listItem:last-child) {\n  border-bottom: 1px solid var(--color-text-secondary);\n}\n\n.listItemBackground {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n}\n\n.listItemBackground:hover:not(.disabled) {\n  background-color: var(--color-primary);\n  opacity: 0.16;\n}\n\n.selected {\n  background-color: var(--color-primary);\n  opacity: 0.16;\n}\n\n.disabled {\n  background-color: var(--color-grey-700);\n  opacity: 0.16;\n}\n\n.option {\n  padding: 12px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"templatesField": `select__templatesField--bxpCV`,
	"dropdown": `select__dropdown--B83V-`,
	"isOpened": `select__isOpened--aqDgR`,
	"field": `select__field--6asJj`,
	"input": `select__input--A3sVD`,
	"listItem": `select__listItem--WrY8t`,
	"listItemBackground": `select__listItemBackground--t0hCE`,
	"disabled": `select__disabled--X6EJD`,
	"selected": `select__selected--fDEyw`,
	"option": `select__option--zvOCQ`
};
export default ___CSS_LOADER_EXPORT___;
