// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input__input--F-mHm {
  border: 0;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  background: var(--color-primary-contrast);
  color: var(--color-text);
  font-family: var(--font-family-default);
  font-size: 16px;
  line-height: 24px;
}

.input__input--F-mHm::placeholder {
  color: var(--color-text-secondary);
}

.input__input--F-mHm:disabled::placeholder {
  color: var(--color-disabled);
}
`, "",{"version":3,"sources":["webpack://./src/styles/input.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,sBAAsB;EACtB,mCAAmC;EACnC,yCAAyC;EACzC,wBAAwB;EACxB,uCAAuC;EACvC,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".input {\n  border: 0;\n  box-sizing: border-box;\n  border-radius: var(--border-radius);\n  background: var(--color-primary-contrast);\n  color: var(--color-text);\n  font-family: var(--font-family-default);\n  font-size: 16px;\n  line-height: 24px;\n}\n\n.input::placeholder {\n  color: var(--color-text-secondary);\n}\n\n.input:disabled::placeholder {\n  color: var(--color-disabled);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `input__input--F-mHm`
};
export default ___CSS_LOADER_EXPORT___;
