// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history__divider--60qvR {
  background: var(--color-black--lighten);
  border-radius: 123px;
  padding: 3px 18px;
  color: var(--color-white);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.history__divider__wrapper--OQjSj {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/chat/history/history.module.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,oBAAoB;EACpB,iBAAiB;EACjB,yBAAyB;EACzB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".divider {\n  background: var(--color-black--lighten);\n  border-radius: 123px;\n  padding: 3px 18px;\n  color: var(--color-white);\n  font-size: 14px;\n  line-height: 22px;\n  text-align: center;\n}\n\n.divider__wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `history__divider--60qvR`,
	"divider__wrapper": `history__divider__wrapper--OQjSj`
};
export default ___CSS_LOADER_EXPORT___;
