// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/block.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-info-success__success--w7i2- {
  background-color: var(--color-primary-contrast);
  margin-top: 16px;
}

.contact-info-success__list--BVrql {
  margin: 16px 0 0;
}

.contact-info-success__entry--dAx1K {
  display: flex;
}

.contact-info-success__entry--dAx1K + .contact-info-success__entry--dAx1K {
  margin-top: 4px;
}

.contact-info-success__label--ioefR {
  color: var(--color-text--lighten);
  margin-right: 0.33em;
}

.contact-info-success__value--D7OzQ {
  margin: 0;
  color: var(--color-text);
}

.contact-info-success__value--D7OzQ:before {
  content: '– ';
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/contact-info-success/contact-info-success.module.css"],"names":[],"mappings":"AAAA;EAEE,+CAA+C;EAC/C,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iCAAiC;EACjC,oBAAoB;AACtB;;AAEA;EACE,SAAS;EACT,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".success {\n  composes: block from '~@styles/block.module.css';\n  background-color: var(--color-primary-contrast);\n  margin-top: 16px;\n}\n\n.list {\n  margin: 16px 0 0;\n}\n\n.entry {\n  display: flex;\n}\n\n.entry + .entry {\n  margin-top: 4px;\n}\n\n.label {\n  color: var(--color-text--lighten);\n  margin-right: 0.33em;\n}\n\n.value {\n  margin: 0;\n  color: var(--color-text);\n}\n\n.value:before {\n  content: '– ';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": `contact-info-success__success--w7i2- ${___CSS_LOADER_ICSS_IMPORT_0___.locals["block"]}`,
	"list": `contact-info-success__list--BVrql`,
	"entry": `contact-info-success__entry--dAx1K`,
	"label": `contact-info-success__label--ioefR`,
	"value": `contact-info-success__value--D7OzQ`
};
export default ___CSS_LOADER_EXPORT___;
