import { SettingsService } from '@services/settings/settings.service';
import { Debug } from '@utils/debug/debug.decorator';
import { hexToRgb } from '@utils/helpers';
import { getContrastColor } from '@utils/get-contrast-color';

const colorsKeyByCssVariableName: { [key: string]: string } = {
  TextColor: '--color-text',
  BackgroundColor: '--color-background',
  PrimaryColor: '--color-primary',
  PrimaryContrastColor: '--color-primary-contrast',
  WarningColor: '--color-warning',
  ButtonIconFillColor: '--color-button-icon-fill',
  ButtonBackgroundColor: '--color-button-background',
};

const themeNameByCssVariableName: { [key: string]: string } = {
  Dark: '--dt',
  Light: '--lt',
};
@Debug
export class BrandingService {
  constructor(private _settingsService: SettingsService) {}

  public init(rootNode: HTMLElement): void {
    const { BrandingSettings } = this._settingsService.settings;
    Object.entries(BrandingSettings).forEach(([themeName, themeColors]) => {
      if (themeName in themeNameByCssVariableName) {
        Object.entries(themeColors).forEach(([colorName, colorValue]) => {
          if (colorValue && colorsKeyByCssVariableName[colorName]) {
            const cssVariable = colorsKeyByCssVariableName[colorName] + themeNameByCssVariableName[themeName];
            const cssVariableRgb = `${
              colorsKeyByCssVariableName[colorName] + themeNameByCssVariableName[themeName]
            }--rgb`;
            if (colorName === 'BackgroundColor') {
              rootNode.style.setProperty(
                `${colorsKeyByCssVariableName[colorName]}-contrast${themeNameByCssVariableName[themeName]}`,
                getContrastColor(String(colorValue)),
              );
            }
            rootNode.style.setProperty(cssVariable, colorValue as string);
            rootNode.style.setProperty(cssVariableRgb, hexToRgb(colorValue as string));
          }
        });
      }
    });
  }
}
