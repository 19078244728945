// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadowdom__text--bHH13 {
  white-space: pre-line;
}
`, "",{"version":3,"sources":["webpack://./src/components/shadow-dom/shadowdom.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB","sourcesContent":[".text {\n  white-space: pre-line;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `shadowdom__text--bHH13`
};
export default ___CSS_LOADER_EXPORT___;
