import { IconUnderlay } from '@components/icon-underlay';
import { AbstractStatusEnum } from '@models/abstract-status.enum';
import { IContactInfoFormAttributes } from '@models/message-attributes.interface';
import { formatAmericanPhoneNumber } from '@utils/format-american-phone-number';
import React, { useContext } from 'react';
import styles from './contact-info-success.module.css';
import { ServiceContext } from '@services/service.provider';
import { capitalize } from '@utils/helpers';

type Props = {
  attributes: IContactInfoFormAttributes;
};

export const ContactInfoSuccess: React.FunctionComponent<Props> = ({ attributes }) => {
  const values = attributes.anonymCustomerContactInfo;
  const { settingsService } = useContext(ServiceContext);

  const { FirstName, LastName, Email, Phone, AdditionalInformation } = settingsService.settings.LiveChatTextResources;
  const getVoice = (): string => {
    let voice = 'contact information, ';

    if (values?.firstName) {
      voice += `${FirstName}: ${values?.firstName}, `;
    }
    if (values?.lastName) {
      voice += `${LastName}: ${values?.lastName}, `;
    }
    if (values?.phone) {
      voice += `${Phone}: ${values?.phone}, `;
    }
    if (values?.email) {
      voice += `${Email}: ${values?.email}, `;
    }
    if (values?.info) {
      voice += `${AdditionalInformation}: ${values?.info}, `;
    }

    return voice;
  };

  return values ? (
    <div className={styles.success} data-message={'message'} aria-label={getVoice()} tabIndex={-1}>
      <IconUnderlay
        icon="user-circle"
        status={AbstractStatusEnum.info}
        qaLocator="contact_info_success_icon"
        isCompact
      />
      <dl className={styles.list}>
        {values.firstName && (
          <div className={styles.entry}>
            <dt className={styles.label}>{capitalize(FirstName)}</dt>
            <dd className={styles.value} data-qa="contact_info_success_first_name">
              {values.firstName}
            </dd>
          </div>
        )}
        {values.lastName && (
          <div className={styles.entry}>
            <dt className={styles.label}>{capitalize(LastName)}</dt>
            <dd className={styles.value} data-qa="contact_info_success_last_name">
              {values.lastName}
            </dd>
          </div>
        )}
        {values.phone && (
          <div className={styles.entry}>
            <dt className={styles.label}>{capitalize(Phone)}</dt>
            <dd className={styles.value} data-qa="contact_info_success_phone">
              {formatAmericanPhoneNumber(values.phone ?? '')}
            </dd>
          </div>
        )}
        {values.email && (
          <div className={styles.entry}>
            <dt className={styles.label}>{capitalize(Email)}</dt>
            <dd className={styles.value} data-qa="contact_info_success_email">
              {values.email}
            </dd>
          </div>
        )}
        {values.info && (
          <div className={styles.entry}>
            <dt className={styles.label}>{capitalize(AdditionalInformation)}</dt>
            <dd className={styles.value} data-qa="contact_info_success_info">
              {values.info}
            </dd>
          </div>
        )}
      </dl>
    </div>
  ) : null;
};
