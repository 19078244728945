/* eslint-disable no-console */
import { HTTPClient } from '@api/http-client';
import { LogLevelEnum } from '@api/ILogRequest';
import { isErrorLike } from '@guards/is-error-like.guard';
import { isSpecialErrorResponse } from '@guards/is-special-error-response.guard';
// import { IErrorLike } from '@models/error-like.interface';
import { SettingsService } from '@services/settings/settings.service';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { Debug } from '@utils/debug/debug.decorator';
import { AxiosResponse } from 'axios';

@Debug
export class LoggingService {
  private readonly _defaultMessageSource = 'LiveChat logging service';
  private readonly _onSpecialErrorHandler = this._onSpecialError.bind(this);

  private get _settingLogLevel(): LogLevelEnum {
    return this._settingsService.settings?.LogSettings?.level ?? LogLevelEnum.None;
  }

  private get _logToConsole(): boolean {
    return this._sessionStorageService.get(SessionStorageService.LogToConsole) ?? false;
  }

  private set _logToConsole(isLogToConsole: boolean) {
    this._sessionStorageService.save(SessionStorageService.LogToConsole, isLogToConsole);
  }

  constructor(
    private readonly _httpClient: HTTPClient,
    private readonly _settingsService: SettingsService,
    private readonly _sessionStorageService: SessionStorageService,
  ) {}

  public init(): void {
    this._httpClient.addResponseInterceptor(this._onSpecialErrorHandler);
    this._logToConsole = process.env.NODE_ENV === 'development';
  }

  public async info(message: string, source: string = this._defaultMessageSource): Promise<void> {
    const logMessage = this._formatMessage(source, message);

    if (this._logToConsole || LogLevelEnum.Debug >= this._settingLogLevel) {
      console.info(logMessage);
    }

    await this._write(LogLevelEnum.Info, logMessage);
  }

  public async error(error: unknown, message?: string, source: string = this._defaultMessageSource): Promise<void> {
    const errorMessage = this._convertToLogMessage(error, message);
    const logMessage = this._formatMessage(source, errorMessage);

    console.error(logMessage);

    // if ((error as IErrorLike)?.code) {
    //   //don't write the log if the ApiException error
    //   return;
    // }
    await this._write(LogLevelEnum.Info, logMessage);
  }

  private _convertToLogMessage(error: unknown, message?: string): string {
    let resultMessage = isErrorLike(error)
      ? JSON.stringify({ name: error?.name, message: error.message, code: error?.code })
      : JSON.stringify(error);

    if (message) {
      resultMessage = `${message}\n${resultMessage}`;
    }

    return resultMessage;
  }

  private async _onSpecialError(response: AxiosResponse): Promise<AxiosResponse> {
    const { data } = response;
    if (data && isSpecialErrorResponse(data)) {
      this.error({ message: data.Error.Message, code: data.Error.Code.toString() }, data.Error.Message);
    }
    return response;
  }

  private async _write(logLevel: LogLevelEnum, message: string): Promise<void> {
    try {
      if (logLevel >= this._settingLogLevel) {
        await this._httpClient.writeLog({ Level: logLevel, Message: message });
      }
    } catch (e) {
      console.error(`${this._defaultMessageSource} ${e}`);
    }
  }

  private _formatMessage(source: string, message: string): string {
    return `[${source}]: ${message}`;
  }
}
