import { isInIframe } from '@utils/is-in-iframe';
import { localeStorageKey } from '@utils/get-locale';

const requestLangEvent = 'ac.lc.requestLang';
const responseLangEvent = 'ac.lc.responseLang';
const isLocalDevelopment = process.env.NODE_ENV === 'development';

interface ICustomLangEvent {
  data: { lang: string; type: string };
}

const subscribeToLangResponse = (): void => {
  if (isInIframe() || isLocalDevelopment) {
    window.addEventListener('message', (event: ICustomLangEvent) => {
      if (event.data?.type === responseLangEvent && event.data?.lang) {
        localStorage[localeStorageKey] = event.data.lang;
      }
    });
  }
};

const requestLangFromHostPage = (): void => {
  if (isInIframe() || isLocalDevelopment) {
    window.parent.postMessage({ type: requestLangEvent }, '*');
  }
};

const subscribeToLangRequest = (): void => {
  window.addEventListener('message', (event) => {
    if (event.data.type === requestLangEvent) {
      const { lang } = document.documentElement;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const frames = window.frames as any;
      frames.livechatiframe?.postMessage({ lang, type: responseLangEvent }, '*');
    }
  });
};

export { requestLangFromHostPage, subscribeToLangRequest, subscribeToLangResponse };
