// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/input.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input__wrapper--UeJPs {
  position: relative;
}

.custom-input__wrapper--UeJPs input,
.custom-input__wrapper--UeJPs input:focus,
.custom-input__wrapper--UeJPs input:-webkit-autofill {
  background-color: var(--color-primary-contrast) !important;
  color: var(--color-text) !important;
  -webkit-text-fill-color: var(--color-text) !important;
  -webkit-box-shadow: 0 0 0 1000px var(--color-primary-contrast) inset !important;
}

.custom-input__label--HivUN {
  color: var(--color-text--lighten);
  position: absolute;
  margin-left: 8px;
  transform: translate(0px, 10px);
  transition: all 0.3s ease;
  pointer-events: none;
}

.custom-input__labelToTop--SoxNX {
  transform: translate(-4px, -12px) scale(0.9);
}

.custom-input__focus--9UURy {
  color: var(--color-primary);
}

.custom-input__textarea--v-XkR {
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid var(--color-text-secondary);
  border-radius: 0;
  resize: none;
}

.custom-input__textarea--v-XkR:focus {
  border-bottom: 1px solid var(--color-primary);
}

.custom-input__hint--QWu8G {
  padding-left: 8px;
  margin-top: 16px;
}

.custom-input__info--VM-nS {
  color: var(--color-text-secondary);
}

.custom-input__error--FWgqo {
  color: var(--color-warning);
}
`, "",{"version":3,"sources":["webpack://./src/components/custom-input/custom-input.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;;;EAGE,0DAA0D;EAC1D,mCAAmC;EACnC,qDAAqD;EACrD,+EAA+E;AACjF;;AAEA;EACE,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,+BAA+B;EAC/B,yBAAyB;EACzB,oBAAoB;AACtB;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EAEE,WAAW;EACX,YAAY;EACZ,oDAAoD;EACpD,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".wrapper {\n  position: relative;\n}\n\n.wrapper input,\n.wrapper input:focus,\n.wrapper input:-webkit-autofill {\n  background-color: var(--color-primary-contrast) !important;\n  color: var(--color-text) !important;\n  -webkit-text-fill-color: var(--color-text) !important;\n  -webkit-box-shadow: 0 0 0 1000px var(--color-primary-contrast) inset !important;\n}\n\n.label {\n  color: var(--color-text--lighten);\n  position: absolute;\n  margin-left: 8px;\n  transform: translate(0px, 10px);\n  transition: all 0.3s ease;\n  pointer-events: none;\n}\n\n.labelToTop {\n  transform: translate(-4px, -12px) scale(0.9);\n}\n\n.focus {\n  color: var(--color-primary);\n}\n\n.textarea {\n  composes: input from '~@styles/input.module.css';\n  width: 100%;\n  padding: 8px;\n  border-bottom: 1px solid var(--color-text-secondary);\n  border-radius: 0;\n  resize: none;\n}\n\n.textarea:focus {\n  border-bottom: 1px solid var(--color-primary);\n}\n\n.hint {\n  padding-left: 8px;\n  margin-top: 16px;\n}\n\n.info {\n  color: var(--color-text-secondary);\n}\n\n.error {\n  color: var(--color-warning);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `custom-input__wrapper--UeJPs`,
	"label": `custom-input__label--HivUN`,
	"labelToTop": `custom-input__labelToTop--SoxNX`,
	"focus": `custom-input__focus--9UURy`,
	"textarea": `custom-input__textarea--v-XkR ${___CSS_LOADER_ICSS_IMPORT_0___.locals["input"]}`,
	"hint": `custom-input__hint--QWu8G`,
	"info": `custom-input__info--VM-nS`,
	"error": `custom-input__error--FWgqo`
};
export default ___CSS_LOADER_EXPORT___;
