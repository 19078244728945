// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.animations__pop-out-right-skew-animation--2aHx- {
  animation: animations__popOutRightSkewAnimation--Nb4ie 1.2s ease-out;
}

.animations__pop-out-left-animation--GNdJC {
  animation: animations__popOutLeftAnimation--zAdMI 0.7s ease-out;
}

.animations__top-to-down-animation--wssaO {
  animation: animations__shiverTopToDown--cp6Kp 500ms infinite;
}

@keyframes animations__popOutRightSkewAnimation--Nb4ie {
  0% {
    transform: translateX(1000px) skewX(-15deg);
  }
  50% {
    transform: translateX(-35px) skewX(0deg);
  }
  70% {
    transform: translateX(20px) skewX(-5deg);
  }
  85% {
    transform: translateX(-5px) skewX(0deg);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes animations__popOutLeftAnimation--zAdMI {
  0% {
    transform: translateX(-1000px);
  }
  60% {
    transform: translateX(20px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes animations__shiverTopToDown--cp6Kp {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/animations.module.css"],"names":[],"mappings":"AAAA;EACE,oEAAiD;AACnD;;AAEA;EACE,+DAA4C;AAC9C;;AAEA;EACE,4DAAyC;AAC3C;;AAEA;EACE;IACE,2CAA2C;EAC7C;EACA;IACE,wCAAwC;EAC1C;EACA;IACE,wCAAwC;EAC1C;EACA;IACE,uCAAuC;EACzC;EACA;IACE,0BAA0B;EAC5B;AACF;;AAEA;EACE;IACE,8BAA8B;EAChC;EACA;IACE,2BAA2B;EAC7B;EACA;IACE,4BAA4B;EAC9B;EACA;IACE,0BAA0B;EAC5B;AACF;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,2BAA2B;EAC7B;EACA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".pop-out-right-skew-animation {\n  animation: popOutRightSkewAnimation 1.2s ease-out;\n}\n\n.pop-out-left-animation {\n  animation: popOutLeftAnimation 0.7s ease-out;\n}\n\n.top-to-down-animation {\n  animation: shiverTopToDown 500ms infinite;\n}\n\n@keyframes popOutRightSkewAnimation {\n  0% {\n    transform: translateX(1000px) skewX(-15deg);\n  }\n  50% {\n    transform: translateX(-35px) skewX(0deg);\n  }\n  70% {\n    transform: translateX(20px) skewX(-5deg);\n  }\n  85% {\n    transform: translateX(-5px) skewX(0deg);\n  }\n  100% {\n    transform: translateX(0px);\n  }\n}\n\n@keyframes popOutLeftAnimation {\n  0% {\n    transform: translateX(-1000px);\n  }\n  60% {\n    transform: translateX(20px);\n  }\n  80% {\n    transform: translateX(-10px);\n  }\n  100% {\n    transform: translateX(0px);\n  }\n}\n\n@keyframes shiverTopToDown {\n  0% {\n    transform: translateY(0);\n  }\n  50% {\n    transform: translateY(10px);\n  }\n  100% {\n    transform: translateY(0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pop-out-right-skew-animation": `animations__pop-out-right-skew-animation--2aHx-`,
	"popOutRightSkewAnimation": `animations__popOutRightSkewAnimation--Nb4ie`,
	"pop-out-left-animation": `animations__pop-out-left-animation--GNdJC`,
	"popOutLeftAnimation": `animations__popOutLeftAnimation--zAdMI`,
	"top-to-down-animation": `animations__top-to-down-animation--wssaO`,
	"shiverTopToDown": `animations__shiverTopToDown--cp6Kp`
};
export default ___CSS_LOADER_EXPORT___;
