import { ITwilioCredentials } from '@api/session';
import { Nullable } from '@models/nullable.type';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { storageServicePrefix } from '@services/storage/storage.service';
import { isInIframe } from './is-in-iframe';
import { handleOriginRequest } from './getParentOrigin';

interface ICustomEvent {
  data: {
    eventName: string;
    value: unknown;
    type?: string;
  };
}

const IframeCredsEvent = 'ac.lc.iframeCredsEvent';
export const LogOutCredsKey = `${storageServicePrefix}${SessionStorageService.LogOutCreds}`;
export const LogOutTimeKey = `${storageServicePrefix}${SessionStorageService.LogOutTime}`;

const postLogOutCreds = (value: { credentials: Nullable<ITwilioCredentials>; token: string }): void => {
  if (isInIframe()) {
    window.parent.postMessage({ eventName: IframeCredsEvent, value }, '*');
  } else {
    window.postMessage({ eventName: IframeCredsEvent, value }, '*');
  }
};

const saveIframeCredsByTokenUpdate = (): void => {
  window.addEventListener('message', (event: ICustomEvent): void => {
    if (event.data?.eventName === IframeCredsEvent) {
      sessionStorage.setItem(LogOutCredsKey, JSON.stringify(event.data.value));
    } else if (!isInIframe() && event.data?.type === 'GET_ORIGIN_REQUEST') {
      handleOriginRequest();
    }
  });
};

export { postLogOutCreds, saveIframeCredsByTokenUpdate };
