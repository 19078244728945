type GetOriginResponse = {
  type: 'GET_ORIGIN_RESPONSE';
  origin: string;
};

type GetOriginRequest = {
  type: 'GET_ORIGIN_REQUEST';
};

export const requestParentOrigin = (): void => {
  const message: GetOriginRequest = {
    type: 'GET_ORIGIN_REQUEST',
  };
  window.parent.postMessage(message, '*');
};

export const handleOriginRequest = (): void => {
  const response: GetOriginResponse = {
    type: 'GET_ORIGIN_RESPONSE',
    origin: window.location.origin,
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const frames = window.frames as any;
  frames.livechatiframe.postMessage(response, '*');
};

export const getParentOrigin = (): Promise<string> => {
  const originPromise: Promise<string> = new Promise((resolve) => {
    const handler = (event: MessageEvent): void => {
      const data = event.data as GetOriginResponse;
      if (data?.type === 'GET_ORIGIN_RESPONSE') {
        window.removeEventListener('message', handler);
        resolve(data.origin);
      }
    };

    window.addEventListener('message', handler);
    requestParentOrigin();
  });

  return originPromise;
};
